function formatNumber(number) {
  if (number == null) {
    return null;
  } else {
    try {
      number = Math.round(number)
    } catch {;}
    let value = number
      .toString()
      .replace(/(-?\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    return value;
  }
}

export default formatNumber;
